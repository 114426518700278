<template>
  <div class="financing-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      @handleSearch="handleSearch"
      @changeStatus="changeStatus"
      @changeProject="changeProject"
      @changeChargeType="changeChargeType"
      @changeChargeContent="changeChargeContent"
      :filterConfig="filterConfig"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    >
      <template #status="{ row }">
        <span v-if="row.status == 1" style="color: var(--theme-color)">{{
          row.status_txt
        }}</span>
        <span v-else-if="row.status == -2" style="color: var(--error-color)">{{
          row.status_txt
        }}</span>
        <span
          v-else-if="row.status == -1"
          style="color: var(--text-third-color)"
          >{{ row.status_txt }}</span
        >
        <span v-else>{{ row.status_txt }}</span>
      </template></Wtable
    >
  </div>
</template>
<script>
import { ref, reactive, onMounted, onUnmounted } from "vue";
import { print, exportExcel } from "@/utils/common.js";
import { ElMessage } from "element-plus";
import Wtable from "@/components/wTable/wTable.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./index.json";
import API from "@/plugins/api.js";

export default {
  components: {
    Wtable,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const filterConfig = reactive(config.filterConfig);
    const pagination = config.tableConfig.pagination;
    const columns = config.tableConfig.list;
    const tableTitle = ref("订单管理");
    const tableData = ref([]);
    const page = ref("");
    const keywords = ref("");
    const projectId = ref("");
    const chargeContent = ref("");
    const chargeType = ref("");
    const status = ref(null);

    function fetchData(parmas) {
      let lastParmas = {
        ...parmas,
        keywords: keywords.value,
        project_id: projectId.value,
        resources_type: chargeContent.value,
        pay_type_id: chargeType.value,
        page: page.value,
        status: status.value,
        limit: pagination.pageSize,
      };
      API.getOrder(lastParmas).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }
    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[0].mapData = res;
        }
      });
      API.getChargeWayOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[1].mapData = res;
        }
      });

      API.getStatusOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[2].mapData = res;
        }
      });
      API.getChargeContentOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[3].mapData = res;
        }
      });
      fetchData();
    });

    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const handleSearch = (kwd) => {
      keywords.value = kwd;
      fetchData();
    };
    const changeProject = (data) => {
      projectId.value = data.value;
      fetchData();
    };
    const changeChargeType = (data) => {
      chargeType.value = data.value;
      fetchData();
    };
    const changeStatus = (data) => {
      status.value = data.value;
      fetchData();
    };
    const changeChargeContent = (data) => {
      chargeContent.value = data.value;
      fetchData();
    };
    const propertiesConfig = ref([
      { field: "order_sn", displayName: "订单号" },
      { field: "cp_name", displayName: "车场名称" },
      { field: "money", displayName: "实收金额" },
      { field: "yhmoney", displayName: "优惠金额" },
      { field: "status_txt", displayName: "订单状态" },
      { field: "pay_way", displayName: "收款方式" },
      { field: "ost_name", displayName: "收费员" },
      { field: "resources_type_txt", displayName: "缴费内容" },
      { field: "translate", displayName: "费用周期" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    onUnmounted(() => {
      filterConfig.selects[0].value = "";
      filterConfig.selects[1].value = "";
      filterConfig.selects[2].value = "";
      filterConfig.selects[3].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      filterConfig,
      changeProject,
      changeStatus,
      handleSearch,
      changeChargeType,
      changeChargeContent,
      tableTitle,
      sizeChange,
      handlePrint,
      handleExport,
    };
  },
};
</script>

<style lang='scss'>
.financing-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .ic-title {
    padding-left: 10px;
    color: var(--text-color);
  }
}
</style>